<template>
  <div class="vh-100 d-flex justify-content-center align-items-center">
    <div class="col-sm-12 col-md-6">
      <div v-if="authType == 'firebase'">
        <CCard>
          <CCardHeader>メドシェア CSポータル ログイン</CCardHeader>
          <CCardBody>
            <CButton v-if="user" @click="logoutFirebase">ログアウト</CButton>
            <CRow v-else>
              <vue-form-generator :schema="schema" :model="model" :options="formOptions" />
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton color="primary" @click="loginFirebase">ログイン</CButton>
          </CCardFooter>
        </CCard>
      </div>
      <div v-else>
        <!-- Check that the SDK client is not currently loading before accessing is methods -->
        <div v-if="!$auth.loading">
          <!-- show login when not authenticated -->
          <button v-if="!$auth.isAuthenticated" @click="loginAuth0">Log in</button>
          <!-- show logout when authenticated -->
          <button v-if="$auth.isAuthenticated" @click="logoutAuth0">Log out</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, login, logout } from "@/auth/firebase";
import validators from "vue-form-generator/src/utils/validators";

export default {
  name: "Login",
  data() {
    return {
      user: null,
      authType: process.env.VUE_APP_AUTH_TYPE,
      model: { email: "", password: "" },
      schema: {
        groups: [
          {
            fields: [
              {
                type: "input",
                inputType: "text",
                styleClasses: "col-12",
                label: "メールアドレス",
                model: "email",
                hint: "pharmacloud@gmail.com",
                required: true,
                validator: [validators.string],
              },
              {
                type: "input",
                inputType: "password",
                styleClasses: "col-12",
                label: "パスワード",
                model: "password",
                hint: "password",
                required: true,
                validator: [validators.string],
              },
            ],
          },
        ],
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: false,
      },
    };
  },
  created() {
    auth.onAuthStateChanged((user) => {
      this.user = user;
    });
  },
  methods: {
    loginAuth0() {
      this.$auth.loginWithRedirect();
    },
    logoutAuth0() {
      this.$auth.logout({
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        returnTo: window.location.origin,
      });
    },
    loginFirebase() {
      login(this.model.email, this.model.password)
        .then(() => {
          this.$router.push("/");
        })
        .catch((error) => {
          console.error("Login error:", error);
        });
    },
    logoutFirebase() {
      logout()
        .then(() => {
          this.$router.push("/login");
        })
        .catch((error) => {
          console.error("Logout error:", error);
        });
    },
  },
};
</script>
