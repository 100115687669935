import { authGuard } from "@/auth/authGuard";
import EmptyContainer from "@/containers/EmptyContainer";
import TheContainer from "@/containers/TheContainer";
import { provideRouteData } from "@/utils/routeData";
import Develop from "@/views/develop/Develop";
import Jwt from "@/views/develop/Jwt";
import Profile from "@/views/develop/Profile";
import UserRestore from "@/views/develop/UserRestore";
import Home from "@/views/shared/Home";
import Login from "@/views/shared/Login";
import Page403 from "@/views/shared/Page403";
import Page404 from "@/views/shared/Page404";
import DiffStocks from "@/views/v1/medorder/DiffStocks";
import FixStocks from "@/views/v1/medorder/FixStocks";
import Companies from "@/views/v2/core/companies/Companies";
import CompanyDetails from "@/views/v2/core/companies/CompanyDetails";
import CompanyPharmacies from "@/views/v2/core/companies/CompanyPharmacies";
import CompanyTabs from "@/views/v2/core/companies/CompanyTabs";
import CreateEditCompany from "@/views/v2/core/companies/CreateEditCompany";
import Core from "@/views/v2/core/Core";
import DealerDetails from "@/views/v2/core/dealers/DealerDetails";
import Dealers from "@/views/v2/core/dealers/Dealers";
import DealerTabs from "@/views/v2/core/dealers/DealerTabs";
import BigQueries from "@/views/v2/core/embulkManager/BigQueries";
import BigQueryTables from "@/views/v2/core/embulkManager/BigQueryTables";
import EmbulkManager from "@/views/v2/core/embulkManager/EmbulkManager";
import Embulks from "@/views/v2/core/embulkManager/Embulks";
import EthicalMedicineDetail from "@/views/v2/core/ethicalMedicines/EthicalMedicineDetail.vue";
import EthicalMedicines from "@/views/v2/core/ethicalMedicines/EthicalMedicines.vue";
import EthicalMedicineTabs from "@/views/v2/core/ethicalMedicines/EthicalMedicineTabs.vue";
import UpdateEthicalMedicines from "@/views/v2/core/ethicalMedicines/UpdateEthicalMedicines.vue";
import CreateEditGroup from "@/views/v2/core/groups/CreateEditGroup";
import GroupDetails from "@/views/v2/core/groups/GroupDetails";
import GroupPharmacies from "@/views/v2/core/groups/GroupPharmacies";
import Groups from "@/views/v2/core/groups/Groups";
import GroupTabs from "@/views/v2/core/groups/GroupTabs";
import Inquiries from "@/views/v2/core/inquiries/Inquiries";
import ManualContainer from "@/views/v2/core/manuals/ManualContainer";
import ManualCreateEdit from "@/views/v2/core/manuals/ManualCreateEdit";
import ManualDetail from "@/views/v2/core/manuals/ManualDetail";
import Manuals from "@/views/v2/core/manuals/Manuals";
import ManualsSearch from "@/views/v2/core/manuals/ManualsSearch";
import HotCodeDetails from "@/views/v2/core/masterSources/HotCodeDetails";
import ReceiptCodeDetails from "@/views/v2/core/masterSources/ReceiptCodeDetails";
import IryohokenDetails from "@/views/v2/core/masterSources/IryohokenDetails";
import Iryohokens from "@/views/v2/core/masterSources/Iryohokens";
import MasterSources from "@/views/v2/core/masterSources/MasterSources";
import MedisBaseDetails from "@/views/v2/core/masterSources/MedisBaseDetails";
import MedisBases from "@/views/v2/core/masterSources/MedisBases";
import MedisGs1Details from "@/views/v2/core/masterSources/MedisGs1Details";
import MedisGs1s from "@/views/v2/core/masterSources/MedisGs1s";
import MedisOptionDetails from "@/views/v2/core/masterSources/MedisOptionDetails";
import MedisOptions from "@/views/v2/core/masterSources/MedisOptions";
import StockableItemImportRequests from "@/views/v2/core/masterSources/StockableItemImportRequests";
import StockableItemFixtures from "@/views/v2/core/masterSources/StockableItemFixtures";
import StockableItemFixtureDetails from "@/views/v2/core/masterSources/StockableItemFixtureDetails";
import StockableItemFixtureNew from "@/views/v2/core/masterSources/StockableItemFixtureNew";
import StockableItemFixtureEdit from "@/views/v2/core/masterSources/StockableItemFixtureEdit";
import OrderableItemFixtures from "@/views/v2/core/masterSources/OrderableItemFixtures";
import OrderableItemFixtureDetails from "@/views/v2/core/masterSources/OrderableItemFixtureDetails";
import OrderableItemFixtureNew from "@/views/v2/core/masterSources/OrderableItemFixtureNew";
import OrderableItemFixtureEdit from "@/views/v2/core/masterSources/OrderableItemFixtureEdit";
import UnknownSdcvanDeliveryDRecords from "@/views/v2/core/masterSources/UnknownSdcvanDeliveryDRecords";
import NsipsDetail from "@/views/v2/core/nsipses/NsipsDetail";
import Nsipses from "@/views/v2/core/nsipses/Nsipses";
import CreateEditPharmacy from "@/views/v2/core/pharmacies/CreateEditPharmacy";
import Pharmacies from "@/views/v2/core/pharmacies/Pharmacies";
import PharmacyConfigs from "@/views/v2/core/pharmacies/PharmacyConfigs";
import PharmacyDetails from "@/views/v2/core/pharmacies/PharmacyDetails";
import PharmacyGroups from "@/views/v2/core/pharmacies/PharmacyGroups";
import PharmacyLicenseExpires from "@/views/v2/core/pharmacies/PharmacyLicenseExpires";
import PharmacyLicenses from "@/views/v2/core/pharmacies/PharmacyLicenses";
import PharmacyTabs from "@/views/v2/core/pharmacies/PharmacyTabs";
import PharmacyUsers from "@/views/v2/core/pharmacies/PharmacyUsers";
import CreateEditUser from "@/views/v2/core/users/CreateEditUser";
import CreateEditUserTabs from "@/views/v2/core/users/CreateEditUserTabs";
import UserDetails from "@/views/v2/core/users/UserDetails";
import UserPharmacies from "@/views/v2/core/users/UserPharmacies";
import Users from "@/views/v2/core/users/Users";
import UserTabs from "@/views/v2/core/users/UserTabs";
import V2 from "@/views/v2/V2";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "ログイン",
    component: Login,
    beforeEnter: authGuard,
  },
  { path: "/forbidden", component: Page403 },
  {
    path: "/",
    component: TheContainer,
    beforeEnter: authGuard,
    children: [
      { path: "/", name: "ホーム", component: Home },
      {
        path: "v2",
        redirect: "v2",
        name: "V2",
        component: EmptyContainer,
        children: [
          { path: "/", component: V2 },
          {
            path: "core",
            redirect: "core",
            name: "コア",
            component: EmptyContainer,
            children: [
              { path: "/", component: Core },
              {
                path: "inquiries",
                redirect: "inquiries",
                name: "問合せ一覧",
                component: EmptyContainer,
                children: [{ path: "/", component: Inquiries }],
              },
              {
                path: "manuals",
                redirect: "manuals",
                name: "マニュアル一覧",
                component: ManualContainer,
                children: [
                  { path: "/", component: Manuals },
                  { path: "search", name: "マニュアル検索", component: ManualsSearch },
                  { path: ":id", name: "マニュアル詳細", component: ManualDetail },
                ],
              },
              {
                path: "manuals",
                redirect: "manuals",
                name: "マニュアル一覧（編集）",
                meta: { label: "マニュアル一覧" },
                component: EmptyContainer,
                children: [
                  { path: "new", name: "マニュアル新規作成", component: ManualCreateEdit },
                  { path: ":id/edit", name: "マニュアル編集", component: ManualCreateEdit },
                ],
              },
              {
                path: "pharmacies",
                redirect: "pharmacies",
                name: "薬局一覧",
                component: EmptyContainer,
                children: [
                  { path: "/", component: Pharmacies },
                  { path: "new", name: "薬局新規作成", component: CreateEditPharmacy },
                  {
                    path: ":id",
                    redirect: ":id",
                    name: "薬局情報",
                    component: PharmacyTabs,
                    children: [
                      { path: "/", name: "薬局の詳細", component: PharmacyDetails },
                      { path: "licenses", name: "薬局の開設許可証", component: PharmacyLicenses },
                      { path: "groups", name: "薬局の近隣グループ一覧", component: PharmacyGroups },
                      { path: "users", name: "薬局のユーザ一覧", component: PharmacyUsers },
                      { path: "configs", name: "薬局の設定", component: PharmacyConfigs },
                    ],
                  },
                  { path: ":id/edit", name: "薬局編集", component: CreateEditPharmacy },
                ],
              },
              { path: "pharmacy_license_expires", name: "開設許可証の期限管理", component: PharmacyLicenseExpires },
              {
                path: "users",
                redirect: "users",
                name: "ユーザ一覧",
                component: EmptyContainer,
                children: [
                  { path: "/", component: Users },
                  {
                    path: "new",
                    redirect: "new",
                    name: "ユーザ新規作成",
                    component: CreateEditUserTabs,
                    children: [
                      { path: "/", redirect: "pharmacy" },
                      { path: "pharmacy", name: "薬局ユーザ新規作成", component: CreateEditUser },
                      { path: "admin", name: "管理者ユーザ新規作成", component: CreateEditUser },
                    ],
                  },
                  {
                    path: ":id",
                    redirect: ":id",
                    name: "ユーザ情報",
                    component: UserTabs,
                    children: [
                      { path: "/", name: "ユーザの詳細", component: UserDetails },
                      { path: "pharmacies", name: "ユーザの薬局一覧", component: UserPharmacies },
                    ],
                  },
                ],
              },
              {
                path: "companies",
                redirect: "companies",
                name: "薬局会社一覧",
                component: EmptyContainer,
                children: [
                  { path: "/", component: Companies },
                  { path: "new", name: "薬局会社新規作成", component: CreateEditCompany },
                  {
                    path: ":id",
                    redirect: ":id",
                    name: "薬局会社情報",
                    component: CompanyTabs,
                    children: [
                      { path: "/", name: "薬局会社の詳細", component: CompanyDetails },
                      { path: "pharmacies", name: "薬局会社の薬局一覧", component: CompanyPharmacies },
                    ],
                  },
                  { path: ":id/edit", name: "薬局会社編集", component: CreateEditCompany },
                ],
              },
              {
                path: "groups",
                redirect: "groups",
                name: "近隣グループ一覧",
                component: EmptyContainer,
                children: [
                  { path: "/", component: Groups },
                  { path: "new", name: "近隣グループ新規作成", component: CreateEditGroup },
                  {
                    path: ":id",
                    redirect: ":id",
                    name: "近隣グループ情報",
                    component: GroupTabs,
                    children: [
                      { path: "/", name: "近隣グループの詳細", component: GroupDetails },
                      { path: "pharmacies", name: "近隣グループの薬局一覧", component: GroupPharmacies },
                    ],
                  },
                  { path: ":id/edit", name: "近隣グループ編集", component: CreateEditGroup },
                ],
              },
              {
                path: "dealers",
                redirect: "dealers",
                name: "卸会社一覧",
                component: EmptyContainer,
                children: [
                  { path: "/", component: Dealers },
                  {
                    path: ":id",
                    redirect: ":id",
                    name: "卸会社情報",
                    component: DealerTabs,
                    children: [{ path: "/", name: "卸会社の詳細", component: DealerDetails }],
                  },
                ],
              },
              {
                path: "ethical_medicines",
                redirect: "ethical_medicines",
                name: "医療用医薬品一覧",
                component: EmptyContainer,
                children: [
                  { path: "/", component: EthicalMedicines },
                  { path: "update", name: "医療用医薬品更新", component: UpdateEthicalMedicines },
                  {
                    path: ":id(\\d+)",
                    redirect: ":id",
                    name: "医療用医薬品情報",
                    component: EthicalMedicineTabs,
                    props: (route) => ({ id: Number(route.params.id) }),
                    children: [
                      {
                        path: "/",
                        name: "医療用医薬品の詳細",
                        component: EthicalMedicineDetail,
                        props: (route) => ({ id: Number(route.params.id) }),
                      },
                    ],
                  },
                ],
              },
              {
                path: "nsipses",
                redirect: "nsipses",
                name: "NSIPS一覧",
                component: EmptyContainer,
                children: [
                  { path: "/", component: Nsipses },
                  {
                    path: ":id",
                    redirect: ":id",
                    name: "NSIPS一覧（薬局）",
                    component: EmptyContainer,
                    children: [
                      { path: "/", component: Nsipses },
                      { path: ":filename", name: "NSIPS詳細", component: NsipsDetail },
                    ],
                  },
                ],
              },
              {
                path: "embulk_manager",
                redirect: "embulk_manager",
                name: "Embulk管理",
                component: EmptyContainer,
                children: [
                  { path: "/", component: EmbulkManager },
                  {
                    path: "bigqueries",
                    redirect: "bigqueries",
                    name: "BigQueryメニュー",
                    component: EmptyContainer,
                    children: [
                      { path: "/", component: BigQueries },
                      { path: ":serviceName", name: "BigQueryテーブル", component: BigQueryTables },
                    ],
                  },
                  { path: "embulks", name: "Embulkジョブ一覧", component: Embulks },
                ],
              },
              {
                path: "master_sources",
                redirect: "master_sources",
                name: "外部マスターデータ",
                component: EmptyContainer,
                children: [
                  { path: "/", component: MasterSources },
                  {
                    path: "medis_bases",
                    redirect: "medis_bases",
                    name: "医薬品HOT コードマスター",
                    component: EmptyContainer,
                    children: [
                      { path: "/", component: MedisBases },
                      {
                        path: ":id",
                        name: "医薬品HOT コードマスター 詳細",
                        component: MedisBaseDetails,
                      },
                    ],
                  },
                  {
                    path: "medis_gs1s",
                    redirect: "medis_gs1s",
                    name: "医薬品HOT コードマスター GS1コード対応表",
                    component: EmptyContainer,
                    children: [
                      { path: "/", component: MedisGs1s },
                      {
                        path: ":id",
                        name: "医薬品HOT コードマスター GS1コード対応表 詳細",
                        component: MedisGs1Details,
                      },
                    ],
                  },
                  {
                    path: "medis_options",
                    redirect: "medis_options",
                    name: "医薬品HOT コードマスター オプション",
                    component: EmptyContainer,
                    children: [
                      { path: "/", component: MedisOptions },
                      {
                        path: ":id",
                        name: "医薬品HOT コードマスター オプション 詳細",
                        component: MedisOptionDetails,
                      },
                    ],
                  },
                  {
                    path: "iryohokens",
                    redirect: "iryohokens",
                    name: "社会保険資料報酬支払基金マスター",
                    component: EmptyContainer,
                    children: [
                      { path: "/", component: Iryohokens },
                      {
                        path: ":id",
                        name: "社会保険資料報酬支払基金マスター 詳細",
                        component: IryohokenDetails,
                      },
                    ],
                  },
                  {
                    path: "hot_codes",
                    redirect: "hot_codes",
                    name: "HOTコード",
                    component: EmptyContainer,
                    children: [
                      { path: "/", component: EmptyContainer },
                      {
                        path: ":hotCode",
                        name: "HOTコード 詳細",
                        component: HotCodeDetails,
                      },
                    ],
                  },
                  {
                    path: "receipt_codes",
                    redirect: "receipt_codes",
                    name: "レセプト電算コード",
                    component: EmptyContainer,
                    children: [
                      { path: "/", component: EmptyContainer },
                      {
                        path: ":receiptCode",
                        name: "レセプト電算コード 詳細",
                        component: ReceiptCodeDetails,
                      },
                    ],
                  },
                  {
                    path: "stockable_item_import_requests",
                    redirect: "stockable_item_import_requests",
                    name: "更新リクエスト",
                    component: EmptyContainer,
                    children: [{ path: "/", component: StockableItemImportRequests }],
                  },
                  {
                    path: "stockable_item_fixtures",
                    redirect: "stockable_item_fixtures",
                    name: "在庫医薬品マスター手動登録",
                    component: EmptyContainer,
                    children: [
                      { path: "/", component: StockableItemFixtures },
                      {
                        path: "new",
                        name: "在庫医薬品マスター手動登録 新規作成",
                        component: StockableItemFixtureNew,
                      },
                      {
                        path: ":id/edit",
                        name: "在庫医薬品マスター手動登録 編集",
                        component: StockableItemFixtureEdit,
                      },
                      {
                        path: ":id/",
                        name: "在庫医薬品マスター手動登録 詳細",
                        component: StockableItemFixtureDetails,
                      },
                    ],
                  },
                  {
                    path: "orderable_item_fixtures",
                    redirect: "orderable_item_fixtures",
                    name: "発注医薬品マスター手動登録",
                    component: EmptyContainer,
                    children: [
                      { path: "/", component: OrderableItemFixtures },
                      {
                        path: "new",
                        name: "発注医薬品マスター手動登録 新規作成",
                        component: OrderableItemFixtureNew,
                      },
                      {
                        path: ":id/edit",
                        name: "発注医薬品マスター手動登録 編集",
                        component: OrderableItemFixtureEdit,
                      },
                      {
                        path: ":id/",
                        name: "発注医薬品マスター手動登録 詳細",
                        component: OrderableItemFixtureDetails,
                      },
                    ],
                  },
                  {
                    path: "unknown_sdcvan_delivery_d_records",
                    redirect: "unknown_sdcvan_delivery_d_records",
                    name: "不明納品一覧",
                    component: EmptyContainer,
                    children: [{ path: "/", component: UnknownSdcvanDeliveryDRecords }],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "v1",
        name: "V1",
        component: EmptyContainer,
        children: [
          {
            path: "medorder",
            redirect: "medorder",
            name: "メドオーダー",
            component: EmptyContainer,
            children: [
              { path: "/", redirect: "stocks/fix" },
              { path: "stocks/diff", name: "在庫数比較", component: DiffStocks },
              { path: "stocks/fix", name: "在庫数修正", component: FixStocks },
            ],
          },
        ],
      },
      {
        path: "develop",
        redirect: "develop",
        name: "開発",
        component: EmptyContainer,
        children: [
          { path: "/", component: Develop },
          { path: "user-restore", name: "ユーザ復元", component: UserRestore },
          { path: "profile", name: "プロフィール", component: Profile },
          { path: "jwt", name: "JWT", component: Jwt },
        ],
      },
    ],
  },
  { path: "*", component: Page404 },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

provideRouteData(router);

export default router;
