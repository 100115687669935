<template>
  <div>
    <CCard>
      <CCardHeader>
        詳細情報
        <div class="card-header-actions">
          <CLink class="card-header-action btn-setting text-primary" :to="{ name: '薬局編集', params: { id: id } }">
            <CIcon name="cil-pencil" />
            編集する
          </CLink>
        </div>
      </CCardHeader>
      <CCardBody>
        <CForm>
          <CInput label="契約サービス" :value="displayService(pharmacy)" horizontal plaintext disabled />
          <CInput label="薬局ID" :value="pharmacy.id" horizontal plaintext disabled />
          <CInput label="薬局会社ID" :value="pharmacy.company_id" horizontal plaintext disabled />
          <CInput label="薬局名" :value="pharmacy.name" horizontal plaintext disabled />
          <CInput label="医療機関コード(MICODE)" :value="pharmacy.micode" horizontal plaintext disabled />
          <CInput label="登録番号" :value="displayInvoiceNumber(pharmacy)" horizontal plaintext disabled />
          <CInput label="郵便番号" :value="pharmacy.postal_code" horizontal plaintext disabled />
          <CInput label="住所" :value="fullAddress" horizontal plaintext disabled />
          <CInput label="緯度" :value="pharmacy.latitude" horizontal plaintext disabled />
          <CInput label="経度" :value="pharmacy.longitude" horizontal plaintext disabled />
          <CInput label="電話" :value="pharmacy.phone" horizontal plaintext disabled />
          <CInput label="FAX" :value="pharmacy.fax" horizontal plaintext disabled />
          <CInput label="メールアドレス" :value="pharmacy.email" horizontal plaintext disabled />
          <CInput label="WEB URL" :value="pharmacy.web_url" horizontal plaintext disabled />
          <CInput label="地理案内" :value="pharmacy.navigation" horizontal plaintext disabled />
          <CInput label="予測ロジック" :value="predictor" horizontal plaintext v-if="hasAdminRole" disabled />
          <CTextarea label="ノート" :value="pharmacy.note" horizontal plaintext disabled />
          <CInput label="作成日" :value="pharmacy.created_at" horizontal plaintext disabled />
          <CInput label="更新日" :value="pharmacy.updated_at" horizontal plaintext disabled />
          <CInput label="作成者" :value="pharmacy.created_by" horizontal plaintext disabled />
          <CInput label="更新者" :value="pharmacy.updated_by" horizontal plaintext disabled />
          <CInput label="運用開始日" :value="pharmacy.started_on" horizontal plaintext disabled />
          <CInput label="運用終了日" :value="pharmacy.ended_on" horizontal plaintext disabled />
          <CInput label="削除日" :value="pharmacy.deleted_on" horizontal plaintext disabled />
        </CForm>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { hasAuth0AdminRole } from "@/store/auth0Store";
import { hasFirebaseAdminRole } from "@/auth/firebase";

export default {
  name: "PharmacyDetails",
  data() {
    return {
      id: null,
      pharmacy: {},
    };
  },
  computed: {
    fullAddress() {
      return this.pharmacy.address1 + this.pharmacy.address2 + this.pharmacy.address3 + (this.pharmacy.address4 || "");
    },
    predictor() {
      if (this.pharmacy.predict_config != null) {
        return this.pharmacy.predict_config.predictor == "optimized_orders"
          ? "予測 v1 最適化ロジック"
          : this.pharmacy.predict_config.predictor == "statistic"
          ? "予測 v2 統計的ロジック"
          : "未設定";
      } else {
        return "未設定";
      }
    },
    hasAdminRole() {
      const hasAdmin = process.env.VUE_APP_AUTH_TYPE == "firebase" ? hasFirebaseAdminRole : hasAuth0AdminRole;
      return hasAdmin;
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchPharmacy();
  },
  methods: {
    async fetchPharmacy() {
      if (this.id) this.pharmacy = (await this.$axios.get(`/api/v2/core/admin/pharmacies/${this.id}`)).data;
    },
    displayService(pharmacy) {
      if (!pharmacy.services) return "[]";
      if (pharmacy.services.length === 0) return "[]";
      return `[${pharmacy.services}]`;
    },
    displayInvoiceNumber(pharmacy) {
      if (!pharmacy.pharmacy_invoice) return "";
      return pharmacy.pharmacy_invoice.invoice_number;
    },
  },
};
</script>
