





































































































import { getInstance } from "@/auth";
import { getCurrentUserId } from "@/auth/firebase";
import { ChatMessageClass } from "@/models/lib/vue-advanced-chat/chatMessageClass";
import { ChatRoomClass } from "@/models/lib/vue-advanced-chat/chatRoomClass";
import axios from "@/plugins/axios";
import { useInquiryMessagesStore } from "@/store/inquiryMessagesStore";
import { useInquiryRoomsStore } from "@/store/inquiryRoomsStore";
import { InquiryRoomsFindListParams } from "@/store/interfaces/inquiryRoomsFindListParams";
import { doDownload } from "@/utility/windowHelper";
import { uniqueArrayObjectsById } from "@/utils/uniqueArrayObjects";
import DeleteConfirmationModal from "@/views/shared/DeleteConfirmationModal.vue";
import ImageModal from "@/views/shared/ImageModal.vue";
import { InquiryFilter } from "@/views/v2/core/inquiries/inquiryFilter";
import InquiryFilterModal from "@/views/v2/core/inquiries/InquiryFilterModal.vue";
import InquiryMessageModal from "@/views/v2/core/inquiries/InquiryMessageModal.vue";
import InquiryTitleModal from "@/views/v2/core/inquiries/InquiryTitleModal.vue";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { createConsumer } from "actioncable-jwt";
import ChatWindow, { CustomActions, Message, MessageFile, Room, StringNumber } from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import { DeleteMessage, FetchedMessage, MenuAction, OpenFile } from "vue/types/vue-advanced-chat";

export default defineComponent({
  name: "Inquiries",
  components: { ChatWindow, DeleteConfirmationModal, InquiryTitleModal, InquiryFilterModal, InquiryMessageModal, ImageModal },
  setup(props, context) {
    let roomCable: ActionCableJwt.Cable | undefined = undefined;
    let messageCable: ActionCableJwt.Cable | undefined = undefined;
    let roomChannel: ActionCableJwt.Channel | undefined = undefined;
    let messageChannel: ActionCableJwt.Channel | undefined = undefined;
    let creatingMessageChannel = false;

    const isOpenDeleteModal = ref<boolean>(false);
    const isOpenImageModal = ref<boolean>(false);
    const isOpenInquiryFilterModal = ref<boolean>(false);
    const isOpenInquiryMessageModal = ref<boolean>(false);
    const isOpenInquiryTitleModal = ref<boolean>(false);
    const messageModalMode = ref<string>("send"); // possibly ["send", "edit"]
    const inquiryTitle = ref<string>("");
    const imageFile = ref<MessageFile>(null);
    const roomsLoading = ref<boolean>(true);
    const roomsLoaded = ref<boolean>(false);
    const messagesLoaded = ref<boolean>(true);
    const loadFirstRoom = ref<boolean>(false);
    const roomId = ref<StringNumber | undefined | null>(null);
    const messages = ref<ChatMessageClass[]>([]);
    const submittingMessage = ref<Message>(null);
    const deletingMessage = ref<DeleteMessage>(null);
    const rooms = ref<ChatRoomClass[]>([]);
    const currentUserId = ref<number | undefined>(undefined);
    const inquiryFilter = ref<InquiryFilter>(new InquiryFilter({ pharmacyId: undefined, status: undefined }));

    const showFooter = computed(() => {
      const selectingRoom = rooms.value.find((r: Room) => r.roomId === roomId.value);
      return !(selectingRoom?.completed || false);
    });

    async function subscribeRooms() {
      let jwtToken;
      if (process.env.VUE_APP_AUTH_TYPE == "firebase") {
        const u = await getCurrentUserId();
        jwtToken = u;
      } else {
        jwtToken = await getInstance().getJwtToken();
      }
      roomChannel?.unsubscribe();
      roomCable?.disconnect();

      roomCable = createConsumer(`${process.env.VUE_APP_V2_API_CORE_HOST_WS}/cable`, jwtToken);
      roomChannel = roomCable?.subscriptions.create("AdminInquiryRoomChannel", {
        // connected: () => console.log("connected rooms"),
        // disconnected: () => console.log("disconnected rooms"),
        received: (newRoom: ChatRoomClass) => {
          const oldIndex = rooms.value.findIndex((r: ChatRoomClass) => r.id === newRoom.id);
          const newRoomClass = ChatRoomClass.create(newRoom);
          if (oldIndex >= 0) {
            rooms.value[oldIndex] = newRoomClass;
            rooms.value = [...rooms.value]; // 画面の再描画のため
          } else {
            rooms.value = [newRoomClass, ...rooms.value];
          }
        },
      });
    }

    async function subscribeMessages(roomId: StringNumber | undefined) {
      if (!roomId) return;

      try {
        if (creatingMessageChannel) return;
        creatingMessageChannel = true;

        // 接続済みチャネルがあり、roomId に変更がなければ、処理を抜ける
        if (messageChannel) {
          const old_room_id = JSON.parse(messageChannel.identifier).room_id;
          if (old_room_id === roomId) return;
        }

        let jwtToken;
        if (process.env.VUE_APP_AUTH_TYPE == "firebase") {
          const u = await getCurrentUserId();
          jwtToken = u;
        } else {
          jwtToken = await getInstance().getJwtToken();
        }
        await messageChannel?.unsubscribe();
        await messageCable?.disconnect();

        messageCable = await createConsumer(`${process.env.VUE_APP_V2_API_CORE_HOST_WS}/cable`, jwtToken);
        messageChannel = messageCable?.subscriptions.create(
          { channel: "AdminInquiryMessageChannel", room_id: roomId },
          {
            connected: () => (creatingMessageChannel = false),
            disconnected: () => (creatingMessageChannel = false),
            received: (newMsg: ChatMessageClass) => {
              const oldMsgIndex = messages.value.findIndex((m: ChatMessageClass) => m.id === newMsg.id);
              if (oldMsgIndex >= 0) {
                messages.value[oldMsgIndex] = ChatMessageClass.create(newMsg);
                messages.value = [...messages.value]; // 画面の再描画のため
              } else {
                const newMessage = ChatMessageClass.create(newMsg);
                newMessage.seen = newMessage.sender_id === currentUserId.value;
                messages.value = [...messages.value, newMessage];
              }
            },
          }
        );
      } finally {
        creatingMessageChannel = false;
      }
    }

    async function sendMessage(m: Message) {
      submittingMessage.value = m;
      messageModalMode.value = "send";
      isOpenInquiryMessageModal.value = true;
    }

    async function editMessage(m: Message) {
      submittingMessage.value = m;
      messageModalMode.value = "edit";
      isOpenInquiryMessageModal.value = true;
    }

    async function deleteMessage(m: DeleteMessage) {
      deletingMessage.value = m;
      isOpenDeleteModal.value = true;
    }

    async function doDeleteMessage() {
      const url = `/api/v2/core/admin/inquiry_messages/${deletingMessage.value?.message._id}`;
      await axios.delete(url);
    }

    const inquiryRoomsStore = useInquiryRoomsStore();
    watch(inquiryRoomsStore.list, (data) => {
      const list = data.map((r: Room) => ChatRoomClass.create(r));
      rooms.value = [...uniqueArrayObjectsById(list)];
    });

    async function loadRooms() {
      try {
        const params: InquiryRoomsFindListParams = {};
        if (roomId.value) params.roomId = roomId.value;
        if (inquiryFilter.value.pharmacyId) params.pharmacyId = inquiryFilter.value.pharmacyId;
        if (inquiryFilter.value.status) params.status = inquiryFilter.value.status;

        if (!inquiryRoomsStore.hasMore.value) {
          await inquiryRoomsStore.findList(params);
        } else {
          await inquiryRoomsStore.findListMore();
        }

        roomsLoaded.value = !inquiryRoomsStore.hasMore.value;
      } finally {
        roomsLoading.value = false;
      }
    }

    async function fetchMoreRooms() {
      await loadRooms();
    }

    const inquiryMessagesStore = useInquiryMessagesStore();
    watch(inquiryMessagesStore.list, (data) => {
      const list = data
        .map((message: ChatMessageClass) => ChatMessageClass.create(message))
        .sort((a, b) => (a.createdAt?.toMillis() || 0) - (b.createdAt?.toMillis() || 0));
      messages.value = [...list];
    });

    async function loadMessages(room_id: StringNumber | undefined | null) {
      if (!room_id) return;

      if (!inquiryMessagesStore.hasMore.value) {
        await inquiryMessagesStore.findList({ roomId: room_id });
      } else {
        await inquiryMessagesStore.findListMore();
      }

      messagesLoaded.value = !inquiryMessagesStore.hasMore.value;
    }

    async function fetchMessages(message: FetchedMessage) {
      if (roomId.value !== message.room.roomId) inquiryMessagesStore.clearList();
      roomId.value = message.room.roomId;

      await subscribeMessages(message.room.roomId);
      await loadMessages(message.room.roomId);
    }

    function openFile({ message, action }: OpenFile) {
      switch (action) {
        case "preview":
          if (message.file) {
            imageFile.value = message.file;
            isOpenImageModal.value = true;
          }
          break;
        case "download":
          if (!message.file?.url) break;
          doDownload(window, message.file?.url, `${message.file.name}${message.file.type}`);
          break;
      }
    }

    const menuActions: CustomActions = [
      { name: "editInquiryTitle", title: "問合せの件名を変更する" },
      { name: "closeInquiry", title: "問合せを完了にする" },
      { name: "reopenInquiry", title: "問合せを再度オープンする" },
    ];

    async function menuActionHandler({ roomId, action }: MenuAction) {
      const url = `/api/v2/core/admin/inquiry_rooms/${roomId}`;
      switch (action.name) {
        case "editInquiryTitle":
          inquiryTitle.value = rooms.value.find((r: Room) => r.roomId === roomId)?.name || "";
          isOpenInquiryTitleModal.value = true;
          break;
        case "closeInquiry":
          await axios.patch<Array<ChatMessageClass>>(url, { status: "completed" });
          break;
        case "reopenInquiry":
          await axios.patch<Array<ChatMessageClass>>(url, { status: "need_action" });
          break;
      }
    }

    async function loadAll() {
      if (!currentUserId) return;

      await loadRooms();
      await loadMessages(roomId.value);
      await subscribeRooms();
    }

    async function initialize() {
      const response = await axios.get("/api/v2/core/common/users/me");
      currentUserId.value = response.data["user"]["id"];

      const queryRoomId = context.root.$route.query?.roomId;
      roomId.value = typeof queryRoomId === "string" ? parseInt(queryRoomId, 10) : null;
      if (roomId.value) loadFirstRoom.value = true;

      await loadAll();
    }

    initialize();

    watch(inquiryFilter, async (newValue, oldValue) => {
      if (oldValue === undefined) return; // 初回ロードは無視

      roomId.value = null;
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      context.root.$router.replace({ query: {} }).catch(() => {});

      rooms.value = [];
      inquiryRoomsStore.clearList();
      roomsLoading.value = true;
      roomsLoaded.value = false;
      loadFirstRoom.value = false;

      await loadAll();
    });

    return {
      isOpenDeleteModal,
      isOpenImageModal,
      isOpenInquiryFilterModal,
      isOpenInquiryMessageModal,
      isOpenInquiryTitleModal,
      messageModalMode,
      inquiryTitle,
      imageFile,
      roomsLoading,
      roomsLoaded,
      messagesLoaded,
      currentUserId,
      inquiryFilter,
      showFooter,
      rooms,
      roomId,
      loadFirstRoom,
      messages,
      submittingMessage,
      menuActions,
      openFile,
      sendMessage,
      editMessage,
      deleteMessage,
      doDeleteMessage,
      fetchMoreRooms,
      fetchMessages,
      menuActionHandler,
    };
  },
});
