import { getInstance } from "./index";
import { getCurrentUser } from "@/auth/firebase";

function auth0Guard(to, from, next) {
  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      if (authService.hasAdminRole) {
        return next();
      } else {
        return next("/forbidden");
      }
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
}

async function firebaseGuard(to, from, next) {
  const user = await getCurrentUser();
  if (user) {
    if (to.path == "/login") {
      next("/");
    } else {
      next();
    }
  } else {
    if (to.path == "/login") {
      next();
    } else {
      next("/login");
    }
  }
}

export const authGuard = (to, from, next) => {
  if (process.env.VUE_APP_AUTH_TYPE == "firebase") {
    firebaseGuard(to, from, next);
  } else {
    auth0Guard(to, from, next);
  }
};
