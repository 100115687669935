import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";

const FIREBASE_API = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
};

const firebaseApp = (() => {
  if (!getApps().length) {
    return initializeApp(FIREBASE_API);
  }
  return getApp();
})();

const auth = getAuth(firebaseApp);

export { auth };

// LOGIN
export const login = async (email, password) => {
  await signInWithEmailAndPassword(getAuth(firebaseApp), email, password);
};

// LOGOUT
export const logout = async () => signOut(getAuth(firebaseApp));

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
};

export const getCurrentUserId = async () => (await getCurrentUser())?.uid || "";

export let hasFirebaseAdminRole = false;
export const isAdmin = (u) => {
  if (!u) return false;
  const attributes = u?.reloadUserInfo?.customAttributes;
  let claims;
  try {
    claims = JSON.parse(attributes);
  } catch (e) {
    console.log(e);
  }
  return !!claims?.admin;
};
(async () => (hasFirebaseAdminRole = isAdmin(await getCurrentUser())))();
